export const reducerTypes = {
  GET_USER: "reducer/GET_USER",
  GET_ALL_USERS: "reducer/GET_ALL_USERS",
  GET_CITIES: "reducer/GET_CITIES",
  GET_BASES: "reducer/GET_BASES",
  GET_LOGS_CITIES: "reducer/GET_LOGS_CITIES",
  GET_LOGS_BASES: "reducer/GET_LOGS_BASES",
  GET_COUNTRY_FOR_CHECK: "reducer/GET_COUNTRY_FOR_CHECK",
  GET_SELECTED_LANG: "reducer/GET_SELECTED_LANG",
  GET_COUNTRY: "reducer/GET_COUNTRY",
  GET_SELECTED_LOCALE: "reducer/GET_SELECTED_LOCALE",
  GET_ALERT: "reducer/GET_ALERT",
  GET_SPEAKER_TEMPLATES: "reducer/GET_SPEAKER_TEMPLATES",
  GET_LISTS: "reducer/GET_LISTS",
};

export const sagaTypes = {
  GET_USER: "saga/GET_USER",
};
